<template>
  <v-container fluid class="wLightGray secondary-container">
    <div
      class="secondary-container"
      :class="$vuetify.breakpoint.lgAndUp ? 'line' : ''"
    >
      <router-link to="/capitulos" class="font-weight-bold">
        <v-icon> mdi-chevron-left </v-icon>
        <span> Volver a mis capítulos </span>
      </router-link>
    </div>
    <v-sheet class="rounded-xl mb-15 mb-md-0 mt-lg-0 mt-4 secondary-container">
      <v-row
        :class="
          $vuetify.breakpoint.lgAndUp ? 'container-85-vh' : 'container-75-vh'
        "
      >
        <v-col cols="12" class="px-8 pt-5">
          <h2 class="wDarkerBlue--text font-weight-black">
            Vamos a crear un nuevo capítulo, sección o sucursal de tu
            organización
          </h2>
          <h5 class="subtitle">
            Este nuevo capítulo tendrá su propio tablero como local, pero estará
            supeditado al tablero general de tu matriz.
          </h5>
        </v-col>
        <v-col cols="12">
          <v-row class="justify-center">
            <v-col cols="12" md="8" lg="6">
              <v-card tile elevation="0" class="align--transform pb-6">
                <v-form v-model="validCreateEventForm" ref="createEventForm">
                  <v-container>
                    <fieldset v-if="step === 1">
                      <v-col cols="12">
                        <span class="font-weight-bold bluechamber"
                          >¿Cómo se llamará tu capítulo o sección?*</span
                        >
                      </v-col>
                      <v-col cols="12">
                        <span
                          >Ponle un nombre que lo diferencie de los demás
                          capítulos o secciones</span
                        >
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Dale un nombre a tu capítulo o sección"
                          v-model="form.company"
                          placeholder="Ej:"
                          filled
                          rounded
                        ></v-text-field>
                      </v-col>
                      <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                          <v-img
                            lazy-src="https://firebasestorage.googleapis.com/v0/b/appsesion-bd6a4.appspot.com/o/usuario.png?alt=media&token=14901dea-a3d5-4c81-b39d-9d4d6925f0a6"
                            max-height="60"
                            max-width="60"
                            contain
                            src="https://firebasestorage.googleapis.com/v0/b/appsesion-bd6a4.appspot.com/o/usuario.png?alt=media&token=14901dea-a3d5-4c81-b39d-9d4d6925f0a6"
                          >
                          </v-img>
                        </v-col>

                        <v-col cols="12">
                          <v-file-input
                            show-size
                            counter
                            multiple
                            label="Cargar Logotipo"
                            prepend-icon="add"
                            color="#192533"
                            v-model="form.file"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <fieldset v-if="step === 2">
                      <v-row>
                        <v-col cols="12">
                          <span class="font-weight-bold"
                            >¿Quién administrará este capítulo o sección?*</span
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            >Ingresa los datos del usuario encargado de
                            administrar el capítulo O sección que estás
                            creando</span
                          >
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            label="Nombre"
                            placeholder="Ej. Daniel"
                            filled
                            rounded
                            :rules="eventNameRules"
                            v-model="form.name"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Apellidos"
                            placeholder="Ej. Gutierrez"
                            filled
                            rounded
                            v-model="form.firstLastname"
                            :rules="eventNameRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Cargo"
                            placeholder="Ej. Director, Presidente"
                            filled
                            rounded
                            v-model="form.position"
                            :rules="eventNameRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="E-mail"
                            placeholder="Ej: example@mail.com"
                            filled
                            rounded
                            v-model="form.email"
                            :rules="eventNameRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Contraseña"
                            placeholder="Password1*"
                            filled
                            rounded
                            v-model="form.password"
                            :rules="eventNameRules"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            label="Confirma contraseña"
                            placeholder="Password1*"
                            filled
                            rounded
                            :rules="eventNameRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <fieldset v-if="step === 3">
                      <v-row class="mb-5">
                        <v-col cols="12">
                          <span class="font-weight-bold"
                            >¿A qué región pertenece?*</span
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            >Selecciona o crea la región a la que pertenece el
                            capítulo o sección que estas creando</span
                          >
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            chips
                            deletable-chips
                            :items="items"
                            v-model="form.region"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </fieldset>
                    <fieldset v-if="step === 4">
                      <v-col cols="12" class="d-flex justify-center">
                        <v-img
                          lazy-src="https://firebasestorage.googleapis.com/v0/b/appsesion-bd6a4.appspot.com/o/usuario.png?alt=media&token=14901dea-a3d5-4c81-b39d-9d4d6925f0a6"
                          max-height="60"
                          max-width="60"
                          contain
                          src="https://firebasestorage.googleapis.com/v0/b/appsesion-bd6a4.appspot.com/o/usuario.png?alt=media&token=14901dea-a3d5-4c81-b39d-9d4d6925f0a6"
                        >
                        </v-img>
                      </v-col>
                      <v-col cols="12">
                        <span
                          class="font-weight-bold bluechamber headtitle center"
                          >{{ this.form.company }}</span
                        >
                      </v-col>
                      <v-row>
                        <v-col cols="6">
                          <span class="bluechamber headtitle right"
                            >{{ this.form.position }} -</span
                          >
                        </v-col>
                        <v-col cols="6">
                          <span class="left"
                            >{{ this.form.name }}
                            {{ this.form.firstLastname }}</span
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <span class="bluechamber headtitle right"
                            >E-MAIL-</span
                          >
                        </v-col>
                        <v-col cols="6">
                          <span class="left">{{ this.form.email }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <span class="bluechamber headtitle right"
                            >REGIÓN -</span
                          >
                        </v-col>
                        <v-col cols="6">
                          <span class="left">{{ this.form.region }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <span class="bluechamber headtitle right"
                            >Contraseña -</span
                          >
                        </v-col>
                        <v-col cols="4">
                          <span class="left">{{ this.form.password }}</span>
                        </v-col>
                        <v-col cols="12">
                          <span
                            >Una vez finalizado, este nuevo capítulo tendrá un
                            tablero propio al que podrá acceder el administrador
                            asignado con su respectivo usuario y
                            contraseña.o</span
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            >El administrador general tendrá acceso a los
                            tableros de cada uno de los capítulos locales de la
                            organización.</span
                          >
                        </v-col>
                        <v-col cols="6">
                          <router-link
                            to="/nuevo-capitulo"
                            class="font-weight-bold bluetext"
                          >
                            <v-icon class="bluetext"> mdi-chevron-left </v-icon>
                            <span> Volver </span>
                          </router-link>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            color="#141d30"
                            @click="createNewBranch()"
                            class="font-weight-bold whitetext spaceb"
                            :class="
                              $vuetify.breakpoint.smAndUp ? 'rounded-xl' : ''
                            "
                          >
                            <v-icon> check </v-icon>
                            Confirmar y crear
                          </v-btn>
                        </v-col>
                      </v-row>
                    </fieldset>

                    <v-row class="mt-3 mb-1" justify="space-between">
                      <v-col cols="12" class="d-flex justify-space-between">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="step == 1"
                              class="mx-2"
                              fab
                              small
                              color="accent"
                              v-bind="attrs"
                              v-on="on"
                              @click="prev()"
                            >
                              <v-icon dark> mdi-chevron-left </v-icon>
                            </v-btn>
                          </template>
                          <span>Regresar</span>
                        </v-tooltip>
                        <!--!validCreateEventForm || -->
                        <v-tooltip top max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="step == 4"
                              class="mx-2"
                              fab
                              small
                              color="accent"
                              v-bind="attrs"
                              v-on="on"
                              @click="next()"
                            >
                              <v-icon dark> mdi-chevron-right </v-icon>
                            </v-btn>
                          </template>
                          <span v-if="validCreateEventForm">Siguiente</span>
                          <span v-else
                            >Verifica que hayas llenado los datos correctamente
                            para poder avanzar</span
                          >
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" :class="step === 3 ? '' : 'pt-md-12'">
                        <v-row>
                          <v-progress-linear
                            color="accent"
                            :value="progress"
                          ></v-progress-linear>
                        </v-row>
                        <v-row justify="center">
                          <span class="font-weight-bold">{{ step }} de 4</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
    <v-snackbar
      :multi-line="true"
      :top="true"
      :right="true"
      :timeout="time"
      v-model="isVisible"
      :color="color"
      style="z-index: 249"
      rounded="xl"
      transition="slide-x-reverse-transition"
    >
      <span class="subtitle-2 font-weight-bold">{{ text }}</span>
    </v-snackbar>
  </v-container>
</template>
<script>
import { validations } from "@/share/validations.js";
import { mapState, mapMutations, mapActions } from "vuex";
import { TiptapVuetify } from "tiptap-vuetify";
export default {
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      items: ["Norte", "Sur", "Central", "Este"],
      validCreateEventForm: false,
      form: {
        name: "",
        company: "",
        firstLastname: "",
        email: "",
        password: "",
        position: "",
        region: "",
        file: "",
      },
      step: 1,
      alertBool: false,
    };
  },
  computed: {
    ...mapState("notification", ["text", "color", "time", "visible"]),
    isVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.hide();
      },
    },
  },
  methods: {
    ...mapActions("branches", ["createBranch"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    /**
     * Función para validar los campos del la creación del evento
     */
    validateCreateEventForm() {
      if (this.$refs.createEventForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    async createNewBranch() {
      this.loading();
      try {
        const response = await this.createBranch(this.form);

        if (response.status === 200) {
          this.loaded();
          this.$router.push("/capitulos");
          this.show({
            text: "Capítulo creado exitosamente!",
            color: "success",
          });
        } else {
          this.loaded();
          this.show({
          text: "¡Ha ocurrido un problema al crear el capítulo, o ya existe!",
          color: "error",
        });
        }
      } catch (err) {
        console.log(err);
        this.show({
          text: err,
          color: "error",
        });
      }
    },
    prev() {
      this.step--;
    },
    next() {
      // La validación para el paso 6 se hace diferente, debido a que las validaciones de campos de vuetify no se puede hacer en el componente de "gmap-autocomplete"
      if (this.step == 6) {
        if (this.place != null) {
          this.step++;
          // Reiniciar validación del formulario
          this.$refs.createEventForm.resetValidation();
        }
        if (this.form.eventModality == "VIRTUAL") {
          this.step++;
          // Reiniciar validación del formulario
          this.$refs.createEventForm.resetValidation();
        } else {
          this.validCreateEventForm = false;
        }
      } else if (this.validateCreateEventForm() && this.step != 6) {
        this.step++;
        // Reiniciar validación del formulario
        this.$refs.createEventForm.resetValidation();
      }
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    progress() {
      return (this.step * 100) / 4;
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}

.subtitle {
  color: #192533;
  font-weight: normal;
}

.bluechamber {
  color: #192533;
}

.headtitle {
  font-weight: 900 !important;
}

.line {
  transform: translateY(-235%);
}

fieldset {
  border: none;
  padding: 0;
}

.orientation--text {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}

.v-text-field.v-text-field--solo .v-input__append-outer,
.v-text-field.v-text-field--solo .v-input__prepend-outer {
  margin-top: 4px !important;
}

.left {
  display: flex;
  justify-content: left;
}

.right {
  display: flex;
  justify-content: right;
}

.center {
  display: flex;
  justify-content: center;
}

.whitetext {
  color: #ffffff;
}
</style>